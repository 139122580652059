.connectpage {
  padding: 3% 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.connectpage img {
  width: 250px;
  border-radius: 20px;
}
.connectpage h1 {
  width: 50%;
}
#email {
  color: #ff7a00;
}
#email:hover {
  color: #ff7a00;
}
.title {
  margin: 10px auto;
  background-color: #a65d3f;
  width: fit-content;
  padding: 10px 40px;
  border-radius: 15px;
}
@media (max-width: 990px) {
  .connectpage h1 {
    margin-top: auto;
    font-size: 20px;
    margin-bottom: auto;
  }
}
@media (max-width: 700px) {
  .connectpage {
    flex-direction: column;
    align-items: center;
  }
  .connectpage img {
    width: 200px;
  }
  .connectpage h1 {
    padding-top: 20px;
    font-size: 15px !important;
  }
}
