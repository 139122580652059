.title {
  margin: 10px auto;
  background-color: #a65d3f;
  width: fit-content;
  padding: 10px 40px;
  border-radius: 15px;
}
.values {
  display: flex;
}
.valuesend {
  padding-left: 30px;
}
#padding {
  padding-left: 35px;
  padding-right: 35px;
}
#lower-education-container ul {
  margin-left: 30px;
}
#education-container {
  text-align: center;
}
#lower-education-container {
  display: flex;
  flex-direction: row;
  margin: auto;
  max-width: 1200px;
  font-family: Open sans-serif;
}
.left {
  width: 50%;
}
.right {
  width: 50%;
}
#color {
  font-weight: bold;
  color: #ff7a00;
}
.employee {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.eachemployee {
  padding: 30px;
}
.cards {
  width: 15rem;
}
.font {
  color: black !important;
}
.subtitle {
  font-size: 10px;
}
#imgsize {
  width: 238px;
  height: 238px;
}
@media (max-width: 1020px) {
  .left {
    width: 50%;
  }
  .right {
    width: 50%;
  }
  #lower-education-container {
    align-items: center;
    flex-direction: column;
  }
  #color {
    text-align: center;
  }
  .values {
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .valuesend {
    padding: 0;
  }
  #about {
    text-align: left;
  }
}
.btn {
  position: absolute;
  top: 39%;
  right: 65%;
}
@media (max-width: 900px) {
  .left {
    width: 80%;
  }
  .right {
    width: 80%;
  }
}
