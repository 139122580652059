.MediaPlayer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: center;
}
.audioplayer {
  padding-right: 20px;
  overflow: hidden;
}
.content {
  padding-left: 20px;
  width: 600px;
}
@media (max-width: 1100px) {
  .MediaPlayer {
    padding-top: 20px;
    flex-direction: column;
  }
  .audioplayer {
    padding-right: 0;
    padding-bottom: 20px;
  }
  .content {
    padding-left: 0;
  }
}
@media (max-width: 650px) {
  .MediaPlayer h2 {
    font-size: 20px !important;
  }
  .MediaPlayer h5 {
    font-size: 15px !important;
  }
  .content {
    width: 80%;
  }
}
