/* footer {
  margin-top: auto;
}
.Icons {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.Icons ul {
  display: flex;
  justify-content: center;
  margin-bottom: 0 !important;
}
.Icons li {
  display: inline-block;
  padding: 20px 20px;
}
.Copyright {
  text-align: center;
  font-size: 12px;
}
.Icons img {
  max-width: 30px;
}

@media (max-width: 900px) {
  .Icons img {
    max-width: 25px;
  }
} */
/* *{
  font-family: 'Open Sans', sans-serif ;
} */
#footer {
  background: black;
  padding: 0 0 30px 0;
  color: #fff;
  font-size: 14px;
  margin-top: auto;
}

#footer .footer-top {
  background: #151515;
  border-bottom: 1px solid #222222;
  padding: 30px 0 5px 0;
}

#footer .footer-top .footer-info {
  margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
  font-size: 28px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}

#footer .footer-top .footer-info h3 span {
  color: #ff7a00;
}

#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Raleway", sans-serif;
  color: #fff;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #292929;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #ff7a00;
  color: #151515;
  text-decoration: none;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #ff7a00;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #fff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  color: #ff7a00;
}

#footer .footer-top .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 4px;
}

#footer .footer-top .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 4px;
  width: calc(100% - 110px);
}

#footer .footer-top .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: 0;
  right: -2px;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #ff7a00;
  color: #151515;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
}

#footer .footer-top .footer-newsletter form input[type="submit"]:hover {
  background: #ff7a00;
}

#footer .copyright {
  text-align: center;
  padding-top: 30px;
}

#footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #fff;
}

#bootfooter a,
#bootfooter {
  color: #000000;
  font-size: 1px;
}

#footertitle{
  font-size: 20px !important;
  font-weight: normal !important;
}

#footersubheader{
  
  font-weight: normal !important;
  font-size: 19px !important;
}

#emaillink{
  transition: .3s;
  color: white;
}
#emaillink:hover{
  color: #ff7a00;
}
.subscribebutton{
  color: white;
  border: none;
  width: 230px;
  padding: 5px;
  border-radius: 20px;
  background-color: #ff7a00;
  font-size: 15px;
}
.bottompadding{
  padding-bottom: 20px;
}