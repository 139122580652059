* {
  font-family: Lato;
}
.title {
  margin: 10px auto;
  background-color: #3b3b3b !important;
  width: fit-content;
  padding: 10px 40px;
  border-radius: 15px;
  color: white !important;
}
#SpanColor {
  color: #ff7a00;
}
.Cursor {
  display: none;
}
.Intro {
  padding-top: 100px;
  display: flex;
  text-align: center;
  flex-direction: column;
  padding-bottom: 40px;
  height: 700px;
}
.Intro p {
  font-weight: bold;
  margin-bottom: 0 !important;
  font-size: 88px;
}
.About {
  padding-top: 20px;
  display: flex;
  text-align: center;
  flex-direction: column;
  background-color: #f5f1eb;
  overflow: hidden;
}
.About p {
  background-color: #f5f1eb;
  font-size: 45px;
  color: #25262b;
}
.AboutSection {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  background-color: #f5f1eb;
}
.AboutSection h3 {
  margin-right: 200px;
  text-align: left;
  background-color: #f5f1eb;
  color: #25262b;
  width: 500px;
}
.AboutSection img {
  background-color: black;
  border-radius: 20px;
  width: 350px;
  height: 300px;
  margin-bottom: 50px;
}
.Work {
  padding-top: 20px;
  display: flex;
  text-align: center;
  flex-direction: column;
  padding-bottom: 50px;
}
.Work p {
  font-size: 45px;
}
.WorkCard {
  padding-top: 30px;
  display: flex;
  justify-content: center;
}
.EachCard {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  width: 300px;
  height: 400px;
  background-color: #f5f1eb;
}
.EachCard:hover {
  transition: background-color 500ms;
  background-color: transparent;
}
#Middle {
  margin-left: 50px;
  margin-right: 50px;
}
.EachCard img {
  background-color: transparent;
  width: 175px;
  height: 175px;
}
.Connect {
  background-color: #f5f1eb;
  padding-top: 20px;
  display: flex;
  text-align: center;
  flex-direction: column;
}
.Connect p {
  margin-bottom: 0;
  color: black;
  background-color: #f5f1eb;
  font-size: 45px;
}
.undertitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.undertitle p {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 20px;
  font-weight: normal;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}
.eventlist {
  margin-left: auto;
  margin-right: auto;
  width: 500px;
  padding-top: 30px;
}
th,
td {
  color: black;
  font-weight: normal;
  font-size: 16px;
}
.table,
thead,
th {
  border-width: 2px !important;
  border-color: black !important;
}
@media (max-width: 1150px) {
  .AboutSection {
    flex-direction: column;
    align-items: center;
  }
  .AboutSection h3 {
    margin-right: 0;
    margin-bottom: 30px;
    width: 70%;
  }
  .WorkCard {
    flex-direction: column;
    align-items: center;
  }
  #Middle {
    margin-left: 0;
    margin-right: 0;
  }
  .EachCard {
    margin-bottom: 50px;
  }
}
@media (max-width: 600px) {
  .undertitle p {
    font-size: 20px !important;
    font-weight: normal;
  }
  .Intro p {
    font-size: 56px;
  }
  .AboutSection h3 {
    font-size: 28px;
  }
  .EachCard {
    width: 250px;
    height: 300px;
  }
  .EachCard img {
    background-color: transparent;
    width: 125px;
    height: 125px;
  }
  .eventlist {
    width: 60%;
  }
  .title {
    font-size: 30px !important;
  }
  .undertitle {
    flex-direction: column;
  }
}
