.AlignOuterBox {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}
.title {
  margin: 10px auto;
  background-color: #a65d3f;
  width: fit-content;
  padding: 10px 40px;
  border-radius: 15px;
}
.OuterBox {
  display: flex;
  flex-direction: column;
  width: 900px;
}
.OuterBox p {
  color: #aaaaaa !important;

  font-size: 20px;
}
#paddingtop {
  padding-top: 30px;
}
.InnerBox {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
@media (max-width: 1000px) {
  .OuterBox {
    width: 60%;
  }
}
@media (max-width: 600px) {
  .responsive {
    font-size: 16px;
    text-align: left;
  }
}
