.Introduction {
  text-align: center;
}
.Introduction h3 {
  padding: 20px 15%;
}
.DisplayCard {
  padding-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 60px;
}
.Card {
  display: flex;
  flex-direction: column;
  text-align: center;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}
.Card:hover {
  transform: scale(1.05);
  z-index: 100;
  background: transparent;
}
.Card img {
  width: 400px !important;
}
.Card p {
  padding-top: 15px;
  font-size: 20px;
}
#left {
  padding-right: 50px;
}
#right {
  padding-left: 50px;
}
.title {
  margin: 10px auto;
  background-color: #a65d3f;
  width: fit-content;
  padding: 10px 40px;
  border-radius: 15px;
}
@media (max-width: 1100px) {
  .DisplayCard {
    flex-direction: column;
  }
  .Card img {
    width: 60% !important;
  }
  #left {
    padding-right: 0;
  }
  #right {
    padding-left: 0;
  }
  .responsive {
    font-size: 20px;
  }
}
